<div class="header">
	<h1>Verifications</h1>
	<div>
		<a class="button" [routerLink]="['/create-verifications']">Upload CSV</a>
		<a class="button" [routerLink]="['/create-patient']">Create Verification</a>
		<a class="download" (click)="didClickDownload()"><img src="assets/download.svg"> Download CSV</a>
	</div>
</div>
<div class="filters">
	<select [(ngModel)]="sandbox" (change)="didSelectFilter()">
		<option value="false">
			Production
		</option>
		<option value="true">
			Sandbox
		</option>
	</select>
	<select [(ngModel)]="status" (change)="didSelectFilter()">
		<option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
	</select>
	<div class="search">
		<input [(ngModel)]="searchValue" (ngModelChange)="didChangeSearchValue()" type="search" placeholder="Search by first name, last name or member ID" />
	</div>
</div>
<table>
	<thead>
		<td>Member ID</td>
		<td>Status</td>
		<td>Patient</td>
		<td>Payer</td>
		<td>Created</td>
	</thead>
	<tbody>
		<tr *ngFor="let verification of verifications">
			<td>
				<a [routerLink]="['/verifications', verification.verificationId]">{{ verification.memberId }}</a>
			</td>
			<td>{{ verification.status }}</td>
			<td>{{ verification.firstName }} {{ verification.lastName }}</td>
			<td>{{ verification.payerName }}</td>
			<td>{{ verification.created | date }}</td>
		</tr>
	</tbody>
</table>
