import { Component } from '@angular/core'
import { VERIFICATION_STATUS, VerificationService } from "../@shared/services/verification.service"
import { HostListener } from '@angular/core'
import { Papa } from 'ngx-papaparse'

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html',
  styles: [`
    .download {
      margin-left: 0px
    }
  `]
})
export class VerificationsComponent {

  sandbox: string = "false"
  verifications: any[] = []
  searchValue: string = ""
  status: keyof typeof VERIFICATION_STATUS = 'All'
  statuses = Object.keys(VERIFICATION_STATUS)
  loading = false
  allLoaded = false

  constructor(
    private papa: Papa,
    private verificationService: VerificationService
  ) {}

  async ngOnInit() {
    await this.getVerifications()
  }

  async getVerifications() {
    if (
      this.loading ||
      this.allLoaded
    ) {
      return
    }
    this.loading = true
    const sandbox = this.sandbox == "true"
    const verifications = await this.verificationService.getVerifications(
      sandbox,
      {
        query: this.searchValue,
        status: VERIFICATION_STATUS[this.status]
      },
      this.verifications.length
    )
    if (!verifications.length) {
      this.allLoaded = true
    }
    this.verifications = this.verifications.concat(verifications)
    this.loading = false
  }

  didClickDownload() {
    if (!this.verifications.length) {
      return
    }
    const rows: any = this.verifications.map((row: any) => {
      const { verificationId, status, memberId, firstName, lastName, payerName, created } = row
      const createdDate = new Date(created)
      return {
        verificationId,
        status,
        memberId,
        firstName,
        lastName,
        payerName,
        // format created date to eq 'Dec 13, 2024'
        created: createdDate.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
      }
    })
    const csv = "data:text/csv;charset=utf-8," + this.papa.unparse(rows)
    const encodedUri = encodeURI(csv)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "sohar_verifications.csv")
    document.body.appendChild(link)
    link.click()
  }

  didSelectFilter() {
    this.verifications = []
    this.allLoaded = false
    this.getVerifications()
  }

  timeout = null

  didChangeSearchValue() {
    window.clearTimeout(this.timeout)

    this.timeout = window.setTimeout(() => {
      this.verifications = []
      this.allLoaded = false
      this.getVerifications()
    }, 500)
  }

  @HostListener('window:scroll', ['$event.target'])

  windowScrollEvent(event: KeyboardEvent) {
    const heightOfWholePage = window.document.documentElement.scrollHeight
    const heightOfElement = document.documentElement.scrollHeight
    const currentScrolledY = window.scrollY;
    const innerHeight = window.innerHeight
    const spaceOfElementAndPage = heightOfWholePage - heightOfElement

    const scrollToBottom =
      heightOfElement - innerHeight - currentScrolledY + spaceOfElementAndPage

    if (scrollToBottom < 100) {
      this.getVerifications()
    }
  }
}
